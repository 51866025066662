import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import type { BasicHero_data$key, HeroContentAlignment } from './__generated__/BasicHero_data.graphql';
import { HeroBox } from './hero-box';
import { HeroContentBlocksWrapper } from './hero-content-blocks/HeroContentBlock';

const dataFragment = graphql`
  fragment BasicHero_data on BasicHero {
    useSolidColorBackground
    heroContentAlignment
  }
`;

const LeftAlignContent = styled.div({
  display: 'flex',
  alignSelf: 'flex-end',
  justifySelf: 'flex-start',

  // We are using object-position to align "contained" images in the container
  '--object-position': 'left center',

  [`> ${HeroContentBlocksWrapper}`]: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
});

type BasicHeroComponentProps = {
  data: BasicHero_data$key;
  content: ReactNode;
};

type AlignmentProps = {
  alignment: HeroContentAlignment;
  children: ReactNode;
};

const AlignContent: FC<AlignmentProps> = ({ alignment, children }) => {
  if (alignment === 'LEFT') {
    return <LeftAlignContent>{children}</LeftAlignContent>;
  }

  return children;
};

const BasicHero: FC<BasicHeroComponentProps> = ({ data, content }) => {
  const { useSolidColorBackground, heroContentAlignment } = useFragment(dataFragment, data);

  if (useSolidColorBackground) {
    return (
      <AlignContent alignment={heroContentAlignment}>
        <HeroBox>{content}</HeroBox>
      </AlignContent>
    );
  }

  return <AlignContent alignment={heroContentAlignment}>{content}</AlignContent>;
};

export default BasicHero;
