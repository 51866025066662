/**
 * @generated SignedSource<<710ff7e798c65da17161e054df895cf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HeroContentAlignment = "CENTER" | "LEFT";
import { FragmentRefs } from "relay-runtime";
export type BasicHero_data$data = {
  readonly heroContentAlignment: HeroContentAlignment;
  readonly useSolidColorBackground: boolean;
  readonly " $fragmentType": "BasicHero_data";
};
export type BasicHero_data$key = {
  readonly " $data"?: BasicHero_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BasicHero_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BasicHero_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSolidColorBackground",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heroContentAlignment",
      "storageKey": null
    }
  ],
  "type": "BasicHero",
  "abstractKey": null
};

(node as any).hash = "b7a3b65ee539652d050aa8d38e3b184d";

export default node;
