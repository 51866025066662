import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { HeroColors } from '../hero-colors';

export const HeroBox = styled.div({
  position: 'relative',
  margin: 'auto',
  padding: 24,
  width: 'min(360px, 100%)',
  borderRadius: Color.CornerRadius.Base,
  background: HeroColors.BoxBackground,

  p: {
    color: HeroColors.BoxTextOverride,
  },
});
